import { GetStaticPathsResult, GetStaticPropsContext, GetStaticPropsResult } from 'next';
import React, { useEffect } from 'react';
import H1 from '../components/Fonts/H1';
import MainText from '../components/Fonts/MainText';
import styles from './[username].module.scss';
import LogoIcon from '../icons/logo.svg';
import DownloadBlock from '../components/DownloadBlock';
import Background from '../components/Background';
import Head from 'next/head';
import { captureException } from '@sentry/nextjs';
import { getPhotoSource, serverSideAxios } from '../helpers/axios';
import Account from '../types/Account';

interface AccountPageProps {
  user: Account;
}

const AccountPage = ({ user }: AccountPageProps) => {
  useEffect(() => {
    window.location.replace(`limbiko://${user.username}`);
  }, []);

  const title = `${user.name ? `${user.name} (@${user.username})` : `@${user.username}`} в Реки`;
  const description = `Подписчиков: ${user.subscribersNumber}. ${user.bio || ''}`;

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="profile" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={`https://rekee.ru/${user.username}`} />
        <meta property="og:image" content={getPhotoSource(user.avatar, '', true)} />
        <link rel="canonical" href={`https://rekee.ru/${user.username}`} />
      </Head>
      <Background />
      <div className={styles.foreground}>
        <div className={styles.logo}>
          <LogoIcon height={58} />
        </div>
        <H1 className={styles.h1}>{`@${user.username} в Реки`}</H1>
        <MainText className={styles.mainText}>
          Чтобы посмотреть этот профиль нажмите на кнопку
        </MainText>
        <a className={styles.button} href={`limbiko://${user.username}`}>
          Перейти
        </a>
        <DownloadBlock center />
      </div>
    </>
  );
};

export function getStaticPaths(): GetStaticPathsResult {
  return {
    paths: [],
    fallback: 'blocking',
  };
}

export async function getStaticProps(
  ctx: GetStaticPropsContext,
): Promise<GetStaticPropsResult<AccountPageProps>> {
  const { username } = ctx.params || {};

  if (typeof username !== 'string') {
    return {
      notFound: true,
    };
  }

  try {
    const { data: user } = await serverSideAxios.get(`/account/f/${username}`);

    return {
      props: {
        user: {
          ...user,
          bio: String(user.bio || '').substring(0, 300),
        },
      },
      revalidate: 3600,
    };
  } catch (e) {
    if (e?.response?.status !== 404) {
      captureException(e);
      throw e;
    }
  }

  return {
    notFound: true,
  };
}

export default AccountPage;
